<template>
  <div
    class="card-text-box px-card-text-box"
    @click="clickItem('box')"
    :class="`edit_${Xindex}_box`"
  >
    <h1
      class="top-title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="desc"
      v-html="$util.rex.getHtml(configs[`${nowL}desc`] || configs.desc)"
    ></h2>
    <div class="center">
      <div class="item-box">
        <div
          class="item"
          v-for="(item, index) in configs.items"
          :key="index"
        >
          <img
            class="bgSrc"
            :src="item.bgSrc"
          />
          <div class="item-content">
            <img
              class="icon"
              :src="item.icon2"
            />
            <img
              class="icon icon-hover"
              :src="item.icon"
            />
            <div
              class="title"
              v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
            ></div>
            <div class="red-wire"></div>
            <div
              class="desc_"
              v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  name: 'self-cardAndText',
  mixins: [langMixin],
  data () {
    return {
      isEdit: false,
    };
  },
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          items: [
            {
              icon: '',
              title: '',
              desc: '',
            },
          ],
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card-text-box {
  font-family: PingFang SC-Semibold, PingFang SC;
  padding-top: 70px;
  .top-title {
    font-size: 30px;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }
  .desc {
    font-size: 20px;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 56px;
    background: #fafafa;
    .item-box {
      margin: 0 360px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: 0.3s;
        position: relative;
        width: 25%;
        .remove {
          position: absolute;
          top: 6px;
          right: 10px;
          font-size: 32px;
          z-index: 10;
          &:hover {
            color: var(--themeColor);
          }
        }
        .bgSrc {
          width: 100%;
          height: 100%;
          object-fit: cover; // scale-down;
          filter: brightness(0.45);
          min-height: 400px;
        }
        .item-content {
          width: calc(100% - 108px);
          padding: 0 54px;
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          transform: translate3d(-50%, -50%, 0);
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 10;
          .icon {
            width: 36px;
            &.icon-hover {
              display: none;
            }
          }
          .title {
            font-size: 20px;
            font-weight: 500;
            color: #ffffff;
            margin: 30px 0 48px 0;
            text-align: center;
          }
          .red-wire {
            width: 24px;
            height: 3px;
            background: var(--themeColor);
            display: none;
          }
          .desc_ {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            margin-top: 30px;
            display: none;
            text-align: center;
          }
        }
        &:hover {
          &::after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9;
            background-color: rgba(96, 51, 51, 0.5);
          }
          .red-wire,
          .desc_ {
            display: block;
          }
          .icon {
            display: none;
          }
          .icon-hover {
            display: block !important;
          }
        }
      }
      .add {
        display: flex;
        transition: 0.3s;
        width: 25%;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
        &:hover {
          box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.25),
            -2px -2px 8px 1px rgba(0, 0, 0, 0.25);
          border-radius: 5px 5px 5px 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .px-card-text-box {
    padding-top: 30px;
    .top-title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .desc {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;

      .item-box {
        padding: 0 12px;
        margin: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .item {
          width: calc((100% - 10px) / 2);
          margin-left: 10px;
          margin-top: 10px;
          &::after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9;
            background-color: rgba(96, 51, 51, 0.5);
          }
          .red-wire,
          .desc_ {
            display: block !important;
          }
          .icon {
            display: none;
          }
          .icon-hover {
            display: block !important;
            width: 20px !important;
          }
          .item-content {
            .title {
              font-size: 15px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
            .red-wire {
              width: 14px;
            }
            .desc_ {
              font-size: 11px;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
        .add {
          padding: 20px 0;
          width: calc((100% - 10px) / 2);
          margin-left: 10px;
          margin-top: 10px;
        }
        :nth-child(2n-1) {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
